"use client";

import { HallowAnalyticsEvent, useAnalytics } from "@packages/analytics";
import type { BibleBookId } from "@packages/sdk";
import { usePathname } from "next/navigation";
import type { PropsWithChildren } from "react";
import { createContext, useEffect, useRef, useState } from "react";

export type BibleEntryContext = {
  setData: (
    translationId: number,
    translationName: string,
    book: BibleBookId,
    chapter: number,
  ) => void;
};

export const BibleEntry = createContext<BibleEntryContext>({
  setData: () => {},
});

export const BibleEntryTracker = ({ children }: PropsWithChildren) => {
  const [hasStarted, setHasStarted] = useState<boolean>(false);
  const [book, setBook] = useState<BibleBookId | null>(null);
  const [translationId, setTranslationId] = useState<number | null>(null);
  const [translationName, setTranslationName] = useState<string | null>(null);
  const [chapter, setChapter] = useState<number | null>(null);
  const analytics = useAnalytics();
  const startInstant = useRef<number | null>(null);
  const timer = useRef<NodeJS.Timeout | null>(null);
  const pathname = usePathname();
  const setData = (
    translationId: number,
    translationName: string,
    book: BibleBookId,
    chapter: number,
  ) => {
    setBook(book);
    setTranslationId(translationId);
    setTranslationName(translationName);
    setChapter(chapter);
  };

  useEffect(() => {
    const bibleEventProperties = {
      bible_book_id: book,
      bible_translation_id: translationId,
      bible_translation_name: translationName,
      bible_chapter_id: chapter,
    };

    // path change means we should start waiting again, or not wait at all
    clearTimeout(timer.current);

    if (pathname.includes("/bible") && !hasStarted) {
      timer.current = setTimeout(() => {
        setHasStarted(true);
        startInstant.current = Date.now();
        analytics.track({
          event: HallowAnalyticsEvent.BibleStarted,
          properties: bibleEventProperties,
        });
      }, 5000);
    } else if (
      !pathname.includes("/bible") &&
      hasStarted &&
      Date.now() - startInstant.current > 5000
    ) {
      setHasStarted(false);
      startInstant.current = null;
      analytics.track({
        event: HallowAnalyticsEvent.BibleCompleted,
        properties: bibleEventProperties,
      });
    }
  }, [pathname, book, chapter, translationId, translationName]);

  return (
    <BibleEntry.Provider value={{ setData }}>{children}</BibleEntry.Provider>
  );
};
